import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AmberScore from "../components/reuseable/AmberScore";

const Terms = () => (
  <Layout>
    <SEO title="Amber by inFeedo | Website Terms" />
    <div class="content has-text-centered columns flex-wrap mar-top-160">
            <h2 class="text-block-17 column is-12 is-paddingless">Website Terms of Use</h2>
            <h4 class="has-text-weight-normal column is-12 is-paddingless">How not to get hurt</h4>
            <div className="font-15 pd-40 column is-9 mar-auto has-text-justified">
                <b>Thank you for using our Website!</b><br/><br/>
                Please read these Terms of Service carefully as they contain important information about your legal rights, remedies and obligations. By accessing or using our Website (“ <b>www.infeedo.com</b> ”) and our services, you agree to comply with and be bound by these Terms of Service.
                <br/><br/>
                These Terms of Service ("Terms") constitute a legally binding agreement ("Agreement") between you and inFeedo (the contracting entity being “inFeedo Pvt. Ltd, having its registered office at WeWork, The Galaxy Hotel, Behind 32nd Milestone Off NH 8, Sector 15, Haryana 122001”), which governs your access to and use of our Website including our mobile, tablet and other smart device applications and all associated services including the license to our proprietary software, any updates and up gradation of the same (collectively, "Services"). The website, the applications and the Services are hereinafter collectively called the “Website”.
                <br/><br/>
                Our collection and use of personal information in connection with your access to and use of the Website is described in our Privacy Policy.
                <ol>
                    <li>The Website makes available information relating to our proprietary product, being an AI chat bot, its demonstration, other specifications along with other ancillary services that are being provided by inFeedo. The Website also provides the facility to take on license the product and also make payment towards the existing licenses that have been taken by various clients of inFeedo.</li>
                    <li>To promote the Website other member content may be displayed on other websites, in applications, within emails, and in online and offline advertisements. The Website may contain links to third-party websites or resources (“Third-Party Services”). Such Third-Party Services may be subject to different terms and conditions and privacy practices. inFeedo is not responsible or liable for the availability or accuracy of such Third-Party Services, or the content, products, or services available from such Third-Party Services. Links to such Third-Party Services are not an endorsement by inFeedo of such Third-Party Services.</li>
                    <li>Due to the nature of the Internet, inFeedo cannot guarantee the continuous and uninterrupted availability and accessibility of the Website. inFeedo may restrict the availability of the Website or certain areas or features thereof, if this is necessary in view of capacity limits, the security or integrity of our servers, or to carry out maintenance measures that ensure the proper or improved functioning of the Website. inFeedo may improve, enhance and modify the Website and introduce new services from time to time.</li>
                    <li>inFeedo may make access to and use of the Website, or certain areas or features of the Website, subject to certain conditions or requirements, such as completing a verification process, or a your booking and cancellation history.</li>
                    <li>User verification on the Internet is difficult and we do not assume any responsibility for the confirmation of an user’s identity.</li>
                    <li>The access to or use of certain areas and features, the Website may be subject to separate policies, standards or guidelines, or may require that you accept additional terms and conditions. If there is a conflict between these Terms and terms and conditions applicable to a specific area or feature of the Website, the latter terms and conditions will take precedence with respect to your access to or use of that area or feature, unless specified otherwise.</li>
                    <li>inFeedo reserves the right to modify these Terms at any time in accordance with this provision. If we make changes to these Terms, we will post the revised Terms on the Website and update the “Last Updated” date at the top of these Terms. You understand and agree that your continued access to or use of the Website will constitute acceptance of the revised Terms.</li>
                    <li>You must register an account ("User Account") to access and use certain features of the Website, such as for licensing the product and/or service from the Website. You may register for a User Account using an email address and creating a password. You must provide accurate, current and complete information during the registration process. Since the product is licensed to companies and/or organization, the User Account shall be made using an official e-mail id that may be verified by inFeedo.</li>
                    <li>You are responsible for maintaining the confidentiality and security of your User Account credentials and may not disclose your credentials to any third party. You are liable for any and all activities conducted through your User Account. All payments received pursuant to the use of the User Account shall be deemed to have been paid by the company/client and shall be considered as a genuine consideration and inFeedo shall not be responsible or liable for any refunds due to any fraudulent charges.</li>
                    <li><b>Purchase and Delivery of Product:</b> You may use your User Account for purchasing the limited license for the products /services available on the Website. The license to use the product shall be activated as per the individual contracts that have been executed between inFeedo and the clients.</li>
                    <li>You acknowledge and agree that the Website and the content available on the Website (“ <b>inFeedo Content</b> ”), including all associated intellectual property rights, are the exclusive property of inFeedo and/or its licensors or authorizing third-parties. You will not remove, alter or obscure any copyright, trademark, service mark or other proprietary rights notices incorporated in or accompanying the Website. All trademarks, service marks, logos, trade names, and any other source identifiers of inFeedo used on or in connection with the Website and inFeedo Content are trademarks or registered trademarks of inFeedo. Trademarks, service marks, logos, trade names and any other proprietary designations of third parties used on or in connection with the Website are used for identification purposes only and may be the property of their respective owners.</li>
                    <li>You will not use, copy, adapt, modify, prepare derivative works of, distribute, license, sell, transfer, publicly display, publicly perform, transmit, broadcast or otherwise exploit the Website. No licenses or rights are granted to you by implication or otherwise under any intellectual property rights owned or controlled by inFeedo or its licensors, except for the licenses and rights expressly granted in these Terms.</li>
                    <li>Unless specified otherwise, the license to the product and/or the ancillary services, shall be pre-paid and/or shall be paid against the invoices that have been raised or against the license agreements and/or purchase orders and/or statement of works that have been generated pursuant to the discussions between inFeedo and its clients.</li>
                    <li>Upon execution of the license agreement, raising of the invoice by inFeedo and/or existence of purchase orders and/or statement of works, a legally binding agreement is formed between you [client] and inFeedo, subject to any additional terms and conditions that apply, including in particular the applicable cancellation policy and any rules.</li>
                    <li>inFeedo has tied up with RazorPay (https://razorpay.com/) for facilitating the online payment facility available on the Website. Upon purchasing the license to use the products and/or services from the Website, You shall be bound by the terms and conditions of the payment gateway in addition to the terms stated herein.</li>
                    <li><b>Return, Refund and Cancellation:</b> As a general rule, inFeedo is not bound to make any refunds for the amount of license fees already paid by you [Client] in the event of either termination of the arrangement between the parties or in the event of any occurrence which is not contemplated by either these terms or the terms of the license between the parties. Refunds, if any shall only be governed by the license agreement executed between the parties. Any refunds that may be processed due to any transactions failures and/or any technical failure shall be processed as per the applicable laws.</li>
                    <li>You as an user, you can leave a public review (“<b>Review</b>”) and submit a star rating (“<b>Rating</b>”) about your experience of the Website. Ratings and Reviews are not verified by Website for accuracy and may be incorrect or misleading. Ratings and Reviews however must be as accurate and may not contain any offensive or defamatory language.</li>
                    <li>You are solely responsible for compliance with any and all laws, rules, regulations, and tax obligations that may apply to your use of the Website. In connection with your use of the Website, you will not and will not assist or enable others to:
                        <ul>
                            <li>Breach or circumvent any applicable laws or regulations, agreements with third-parties, third-party rights, or our Terms and Conditions;</li>
                            <li>Use, display, mirror or frame the Website or any individual element within the Website, inFeedo's name, any inFeedo trademark, logo or other proprietary information, or the layout and design of any page or form contained on a page on the Website, without inFeedo's express written consent;</li>
                            <li>Dilute, tarnish or otherwise harm the inFeedo brand in any way, including through unauthorized use, registering and/or using inFeedo or derivative terms in domain names, trade names, trademarks or other source identifiers, or registering and/or using domains names, trade names, trademarks or other source identifiers that closely imitate or are confusingly similar to inFeedo’s domains, trademarks, taglines, or promotional campaigns;</li>
                            <li>Use any robots, spider, crawler, scraper or other automated means or processes to access, collect data or other content from or otherwise interact with the Website for any purpose;</li>
                            <li>Avoid, bypass, remove, deactivate, impair, descramble, or otherwise attempt to circumvent any technological measure implemented by inFeedo or any of inFeedo's providers or any other third party to protect the Website;</li>
                            <li>Take any action that damages or adversely affects, or could damage or adversely affect the performance or proper functioning of the Website;</li>
                            <li>Violate or infringe anyone else’s rights or otherwise cause harm to anyone.</li>
                        </ul>
                    </li>
                    <li>inFeedo may immediately, without notice, terminate this Agreement and/or stop providing access to the Website if (i) you have materially breached your obligations under these Terms, the payments terms, our Policies, (ii) you have violated applicable laws, regulations or third party rights, or (iii) inFeedo believes in good faith that such action is reasonably necessary to protect the personal safety or property of inFeedo and its Website visitors, its other users and clients.</li>
                    <li>If you choose to use the Website, you do so voluntarily and at your sole risk. The Website is provided “as is”, without warranty of any kind, either express or implied.</li>
                    <li>If we choose to conduct identity verification or background checks on any user, to the extent permitted by applicable law, we disclaim warranties of any kind, either express or implied, that such checks will identify prior misconduct by a guest or guarantee that a guest will not engage in misconduct in the future.</li>
                    <li>You assume full responsibility for the choices you make before, during and after your use of the Website.</li>
                    <li>The foregoing disclaimers apply to the maximum extent permitted by law. You may have other statutory rights. However, the duration of statutorily required warranties, if any, shall be limited to the maximum extent permitted by law.</li>
                    <li>Unless your Country of Residence is in the EU, you acknowledge and agree that, to the maximum extent permitted by law, the entire risk arising out of your access to and use of the Website, your publishing or booking of any products and/or services via the Website or any other interaction you have with inFeedo personnel whether in person or online remains with you. Neither inFeedo nor any other party involved in creating, producing, or delivering the Website will be liable for any incidental, special, exemplary or consequential damages, including lost profits, loss of data or loss of goodwill, service interruption, computer damage or system failure or the cost of substitute products or services, or for any damages for personal or bodily injury or emotional distress arising out of or in connection with (i) these Terms, and (ii) from the use of or inability to use the Website. Except for our obligations to pay amounts applicable pursuant to these Terms, in no event will inFeedo’s aggregate liability arising out of or in connection with these Terms and your use of the Website including, but not limited to, payment of the license fee and/or any other applicable charges via the Website, or from the use of or inability to use the Website, exceed the amounts you have paid or owe for taking on license via the Website.</li>
                    <li>You agree to release, defend (at inFeedo’s option), indemnify, and hold inFeedo and its affiliates and subsidiaries, including but not limited to, its officers, directors, employees and agents, harmless from and against any claims, liabilities, damages, losses, and expenses, including, without limitation, reasonable legal fees, arising out of or in any way connected with (i) your breach of these Terms or our Policies, (ii) your improper use of the Website, or (iii) your breach of any laws, regulations or third party rights.</li>
                    <li>You and inFeedo mutually agree that any dispute, claim or controversy arising out of or relating to these Terms or the breach, termination, enforcement or interpretation thereof, or to the use of the Website, (collectively, “Disputes”) will be settled by arbitration. The Arbitration shall be carried out under the Arbitration and Conciliation Act, 1996 (as applicable in India and as amended from time to time).</li>
                    <li>You and inFeedo each agree that the following claims are exceptions to the Arbitration Agreement and will be brought in a judicial proceeding in a court of competent jurisdiction: (i) Any claim related to actual or threatened infringement, misappropriation or violation of inFeedo’s copyrights, trademarks, trade secrets, patents, or other intellectual property rights; (ii) Any claim seeking emergency injunctive relief based on exigent circumstances (e.g., imminent danger or commission of a crime, hacking, cyber-attack).</li>
                    <li>These terms shall be governed by the laws of India, and the courts in New Delhi shall have the exclusive jurisdiction over the matters pertaining to these Terms.</li>
                    <li>We welcome and encourage you to provide feedback, comments and suggestions for improvements to the Website (“Feedback“). You may submit Feedback by emailing us, through the “Contact” section of the Website, or by other means of communication. Any Feedback you submit to us will be considered non-confidential and non-proprietary to you. By submitting Feedback to us, you grant us a non-exclusive, worldwide, royalty-free, irrevocable, sub-licensable, perpetual license to use and publish those ideas and materials for any purpose, without compensation to you.</li>
                    <li>Except as they may be supplemented by additional terms and conditions, policies, guidelines or standards, these Terms constitute the entire Agreement between inFeedo and you pertaining to the subject matter hereof, and supersede any and all prior oral or written understandings or agreements between inFeedo and you in relation to the access to and use of the Website.</li>
                    <li>No joint venture, partnership, employment, or agency relationship exists between you and inFeedo as a result of this Agreement or your use of the Website.</li>
                    <li>These Terms do not and are not intended to confer any rights or remedies upon any person other than the parties.</li>
                    <li>If any provision of these Terms is held to be invalid or unenforceable, such provision will be struck and will not affect the validity and enforceability of the remaining provisions.</li>
                    <li>inFeedo’s failure to enforce any right or provision in these Terms will not constitute a waiver of such right or provision unless acknowledged and agreed to by us in writing. Except as expressly set forth in these Terms, the exercise by either party of any of its remedies under these Terms will be without prejudice to its other remedies under these Terms or otherwise permitted under law.</li>
                    <li>If you have any questions about these Terms please email us.</li>
                </ol>
                <b>These terms were last updated on Sept 05, 2019.</b>
            </div>
    </div>
    <AmberScore/>
  </Layout>
)

export default Terms